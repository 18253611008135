body, h1, h2, p, figure, ul {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
  background: #000;
  min-width: 752px;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  position: relative;
}

body:after {
  content: "";
  z-index: -1;
  opacity: .15;
  background: url("background.50bb974a.jpg") center / cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

h3 {
  font-size: 24px;
  line-height: 24px;
}

p {
  font-size: 18px;
}

a {
  color: #fff;
}

.button {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(90deg, #6842ff 0%, #ca2a9b 80%);
  border: 0;
  border-radius: 0 50px 50px 0;
  height: 80px;
  padding: 0 50px;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: 0;
}

.container {
  width: 95%;
  max-width: 1190px;
  margin: auto;
}

.search-sec {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  display: flex;
}

.search-sec .search-bar {
  width: 100%;
  max-width: 758px;
  height: 80px;
  margin: 40px auto 20px;
  position: relative;
}

.search-sec .search-bar input {
  color: #fff;
  background: #00000078;
  border: 1px solid #6842ff;
  border-radius: 40px;
  outline: 0;
  width: 100%;
  height: 100%;
  padding: 26px 170px 26px 26px;
  font-size: 24px;
}

.game-sec {
  min-height: calc(50vh - 70px);
}

.slider {
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px;
  display: flex;
}

.game-sec .game-thumb {
  width: 19%;
  height: 127px;
}

.game-sec .game-thumb img {
  border-radius: 10px;
  width: 100%;
}

.game-sec .game-thumb-big {
  flex-wrap: wrap;
  gap: 10px;
  width: 32%;
  height: 218px;
  display: flex;
}

.game-sec .game-thumb-big img {
  border-radius: 6px;
  width: calc(50% - 5px);
  height: -moz-fit-content;
  height: fit-content;
}

.category-div {
  cursor: pointer;
  justify-content: center;
  gap: 24px;
  display: flex;
}

.category {
  text-align: center;
  color: #7d7d7d;
  font-size: 14px;
  line-height: 13px;
  transition: all .4s;
}

.category:hover {
  color: #fff;
}

.category img {
  width: 80px;
  margin: 0 auto 10px;
  transition: all .4s;
  display: block;
  transform: scale(1);
}

.category:hover img {
  transform: scale(1.1);
}

.modal {
  z-index: 1;
  box-sizing: border-box;
  background-color: #0006;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  box-sizing: border-box;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 80%;
  height: 80%;
  margin: 50px auto;
  padding: 20px;
  position: relative;
}

.close {
  color: #373434;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: -9px;
  right: 3px;
}

.close:hover, .close:focus {
  cursor: pointer;
  text-decoration: none;
  transform: scale(1.1);
}

.slick-slide {
  margin-right: 5px;
}

.footer {
  justify-content: center;
  gap: 2rem;
  padding: 10px;
  display: flex;
}

.footer a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

/*# sourceMappingURL=index.cb1ec9f9.css.map */
