body,
h1,
h2,
p,
figure,
ul {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Nunito", sans-serif;
  /* font-optical-sizing: auto; */
  min-width: 752px;
  font-weight: 400;
  color: #fff;
  background: #000;
  position: relative;
}
body:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../images/background.jpg) no-repeat center center;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: -1;
  opacity: 0.15;
}
h3 {
  font-size: 24px;
  line-height: 24px;
}
p {
  font-size: 18px;
  /* line-height: 26px; */
}
a {
  color: #fff;
}
.button {
  height: 80px;
  border-radius: 0 50px 50px 0;
  border: 0;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  font-family: "Nunito";
  padding: 0 50px;
  background: linear-gradient(90deg, rgba(104, 66, 255, 1) 0%, rgb(202 42 155) 80%);
  position: absolute;
  right: 0;
  top: 0;
}
.container {
  max-width: 1190px;
  width: 95%;
  margin: auto;
}
.search-sec {
  min-height: 50vh;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}
.search-sec .search-bar {
  max-width: 758px;
  width: 100%;
  height: 80px;
  margin: 40px auto 20px;
  position: relative;
}
.search-sec .search-bar input {
  font-size: 24px;
  background: #00000078;
  border: 1px solid #6842ff;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  padding: 26px 170px 26px 26px;
  color: #fff;
  outline: 0;
}
.game-sec {
  min-height: calc(50vh - 70px);
}
.slider {
  display: flex;
  gap: 10px;
  justify-content: start;
  flex-wrap: wrap;
}
.game-sec .game-thumb {
  height: 127px;
  width: 19%;
}
.game-sec .game-thumb img {
  width: 100%;
  border-radius: 10px;
}
.game-sec .game-thumb-big {
  height: 218px;
  width: 32%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.game-sec .game-thumb-big img {
  width: calc(50% - 5px);
  height: fit-content;
  border-radius: 6px;
}

.category-div {
  display: flex;
  justify-content: center;
  gap: 24px;
  cursor: pointer;
}
.category {
  text-align: center;
  font-size: 14px;
  line-height: 13px;
  color: #7d7d7d;
  transition: 0.4s all ease;
}
.category:hover {
  color: #fff;
}
.category img {
  display: block;
  margin: 0 auto 10px;
  width: 80px;
  transform: scale(1);
  transition: 0.4s all ease;
}
.category:hover img {
  transform: scale(1.1);
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 80%;
  box-sizing: border-box;
  position: relative;
}

.close {
  color: #373434;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 3px;
  top: -9px;
}

.close:hover,
.close:focus {
  transform: scale(1.1);
  text-decoration: none;
  cursor: pointer;
}

.slick-slide {
  margin-right: 5px;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 10px;
}

.footer a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
